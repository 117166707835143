.TextArea {
  :global {
    .ant-tree {
      background-color: transparent;
      .ant-tree-switcher-noop {
        display: none;
      }
      .ant-tree-node-content-wrapper {
        background-color: transparent;
      }
    }
  }
}

