.MusicArea {
  position: fixed;
  right: 25px;
  bottom: 60px;
  z-index: 99;
  width: 100px;
  height: 73px;
  background-image: url('../../../assets/images/music/music_bg.png');
  background-size: cover;
  cursor: pointer;
  .stopImg {
    width: 14.292%;
    height: 14.292%;
    position: absolute;
    top: 49%;
    left: 39%;
    transform: translate(-50%, -50%);
  }
  .playImg {
    width: 15.292%;
    height: 15.292%;
    position: absolute;
    top: 49%;
    left: 39%;
    transform: translate(-50%, -50%);
  }
  .musicArm {
    position: absolute;
    width: 47.5%;
    height: 84.02%;
    top: 10%;
    right: 2%;
    transition-duration: 500ms;
  }
  .armConnected {
    transform: rotate(-4deg);
    transform-origin: 83.416% 9.762%;
  }

  .armUnConnected {
    transform: rotate(10deg);
    transform-origin: 83.416% 9.762%;
  }

  .circle {
    position: absolute;
    width: 81.25%;
    height: 81.25%;
    top: 9.375%;
    left: 9.375%;
    transform-origin: center;
  }

  .circleLoading {
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .musicSymbol {
    position: absolute;
    width: 45.5%;
    height: 45.5%;
    top: 0;
    left: -12%;
  }

  .musicLogo {
    position: absolute;
    width: 40%;
    height: 40%;
    top: 8%;
    right: 0;
  }
}

