@import '~antd/dist/reset.css';

body {
  background-color: #ffffff;
  min-height: 100vh;
  font-family: 'Nexa Bold';
  overflow-y: overlay;
  scroll-behavior: smooth;
}

.main-container {
  padding-left: 24px;
  padding-right: 24px;
}

@media (min-width: 1024px) {
  .main-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1440px) {
  .main-container {
    padding-left: 116px;
    padding-right: 116px;
  }
}

