@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Furore';
  src: url('./assets/fonts/Furore.otf');
  font-display: swap;
}

@font-face {
  font-family: 'zpix';
  src: url('./assets/fonts/zpix.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'PT Root UI Bold';
  src: url('./assets/fonts/PT_Root_UI_Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'PT Root UI Regular';
  src: url('./assets/fonts/PT_Root_UI_Regular.ttf');
  font-display: swap;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  overflow: overlay;
  background: transparent !important;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(178, 186, 203, 0.5);
  border-radius: 3px;
}
