:global {
  .ant-dropdown-placement-bottomLeft {
    .ant-dropdown-menu {
      padding: 0;
      background: #1b9ec1;
      border-radius: 10px;
    }
  }
  .ant-dropdown-menu-item-only-child {
    background: transparent;
    padding: 0 !important;
    .ant-dropdown-menu-title-content {
      color: #ffffff;
      padding: 12px 10px !important;
    }
  }

  .ant-dropdown-menu-item:first-child:hover {
    background: #1b62fa !important;
    border-radius: 10px;
  }
}

.dropdown {
  :global {
    .ant-dropdown-menu-item:first-child:hover {
      background: #1b62fa !important;
      border-radius: 10px 10px 0px 0px;
    }

    .ant-dropdown-menu-item:last-child:hover {
      background: #1b62fa !important;
      border-radius: 0px 0px 10px 10px;
    }
  }
}

