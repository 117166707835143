@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes cloudAnimation1 {
  0% {
    left: 10%;
  }
  50% {
    left: 20%;
  }
  100% {
    left: 10%;
  }
  // 0% {
  //   transform: translate(0, 10%);
  // }
  // 25% {
  //   transform: translate(20%, 0);
  // }
  // 50% {
  //   transform: translate(50%, 10%);
  // }
  // 75% {
  //   transform: translate(20%, 0);
  // }
  // 100% {
  //   transform: translate(0, 10%);
  // }
}

.downArrow {
  position: absolute;
  top: 0.5%;
  left: 50%;
  margin: auto;
  width: 2%;
  height: 45px;
  // transform: translateX(-50%);
  background: url('../../assets/images/downArrow@2x.png') center center
    no-repeat;
  background-size: contain;
  animation: bounce 1s infinite;
}

.cloud1 {
  position: absolute;
  top: 25%;
  left: 12%;
  margin: auto;
  width: 25%;
  height: 10%;
  z-index: 10;
  background: url('../../assets/images/clouds/cloud1@2x.png') center center
    no-repeat;
  background-size: contain;
  //   animation: cloudAnimation1 10s linear infinite;
  //   transition: left 2s ease-in-out;
}

@keyframes rotateAnimation2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cloud2 {
  position: absolute;
  top: 32%;
  right: 0px;
  margin: auto;
  width: 20%;
  height: 15%;
  z-index: 10;
  background: url('../../assets/images/clouds/cloud2@2x.png') center center
    no-repeat;
  background-size: contain;
  // animation: rotateAnimation2 8s linear infinite;
}

.cloud3_1 {
  position: absolute;
  top: 42%;
  left: 12%;
  margin: auto;
  width: 30%;
  height: 9%;
  z-index: 10;
  background: url('../../assets/images/clouds/cloud3_1@2x.png') center center
    no-repeat;
  background-size: contain;
  animation: cloudAnimation 10s linear infinite;
}

.cloud3_2 {
  position: absolute;
  top: 44%;
  left: 64%;
  margin: auto;
  width: 10%;
  height: 9%;
  z-index: 10;
  background: url('../../assets/images/clouds/cloud3_2@2x.png') center center
    no-repeat;
  background-size: contain;
  animation: cloudAnimation 10s linear infinite;
}

@keyframes cloudAnimation4 {
  0% {
    left: -10%;
  }
  50% {
    left: 0;
  }
  100% {
    left: -10%;
  }
}

.cloud4 {
  position: absolute;
  top: 56%;
  left: 0;
  margin: auto;
  width: 30%;
  height: 9%;
  z-index: 10;
  background: url('../../assets/images/clouds/cloud4@2x.png') center center
    no-repeat;
  background-size: contain;
  // animation: cloudAnimation4 10s linear infinite;
}

.cloud5 {
  position: absolute;
  top: 18%;
  right: 0;
  margin: auto;
  width: 30%;
  height: 9%;
  z-index: 10;
  background: url('../../assets/images/clouds/cloud5@2x.png') center center
    no-repeat;
  background-size: contain;
}

.darkCloud {
  position: absolute;
  top: 30%;
  left: 24%;
  margin: auto;
  width: 50%;
  height: 12%;
  z-index: 10;
  background: url('../../assets/images/clouds/dark_clouds@2x.png') center center
    no-repeat;
  background-size: contain;
}

.modal1 {
  :global {
    .ant-modal-content {
      background: url('../../assets/images/modal1Background.png') no-repeat;
      background-size: 100% 100%;
      max-height: 678px;
      width: 800px;

      left: 50%;
      transform: translate(-50%);
      border-radius: 20px;
      .ant-modal-header {
        display: none;
      }
      .ant-modal-footer {
        display: none;
      }
      .ant-modal-close {
        .ant-modal-close-icon {
          display: none;
        }
        & > ::after {
          content: '';
          display: block;
          width: 18px;
          height: 24px;
          background: url('../../assets//images/close.png') no-repeat;
          background-size: 100% auto;
        }
      }
    }

    .ant-tree {
      background-color: transparent;
      .ant-tree-switcher-noop {
        display: none;
      }
      .ant-tree-node-content-wrapper {
        background-color: transparent;
      }
    }
  }
}

.loading {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 100%;

  animation: circle infinite 0.75s linear;
}

// 转转转动画
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.firstScreenCloud {
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 220px;
    width: 100%;
    height: 200px;
    z-index: 0;
    background: url('../../assets/images/cloud@2x.png') no-repeat;
    background-size: 100% auto;
  }
}

.dao {
  background: url('../../assets/images/daoLoading.png') no-repeat;
  background-size: 100%;
}

