.modal1 {
  :global {
    .ant-modal-content {
      background: url('../../../assets/images/modal1Background.png') no-repeat;
      background-size: 100% 100%;
      max-height: 578px;
      max-width: 800px;
      width: 100%;
      left: 50%;
      transform: translate(-50%);
      border-radius: 20px;
      .ant-modal-header {
        display: none;
      }
      .ant-modal-footer {
        display: none;
      }
      .ant-modal-close {
        .ant-modal-close-icon {
          display: none;
        }
        & > ::after {
          content: '';
          display: block;
          width: 18px;
          height: 24px;
          background: url('../../../assets//images/close.png') no-repeat;
          background-size: 100% auto;
        }
      }
    }

    .ant-tree {
      background-color: transparent;
      .ant-tree-switcher-noop {
        display: none;
      }
      .ant-tree-node-content-wrapper {
        background-color: transparent;
      }
    }

    @media screen and (min-width: 867px) {
      .ant-modal-content {
        min-width: 800px;
        max-height: 678px;
      }
    }
  }
}

